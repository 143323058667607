import { memo, useState, useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import SROnly from "common/core/screen_reader";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { Heading, Paragraph, Substyle } from "common/core/typography";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { IconButton } from "common/core/button/icon_button";
import { LongFormattedDateTime } from "common/core/format/date";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { asArchive } from "common/details/bundle/save_all";
import { useMobileScreenClass } from "common/core/responsive";
import { userFullName } from "util/user";

import RetrievalViewerDetailsQuery, {
  type RetrievalViewerDetails_viewer_retrieval_documentBundle as DocumentBundle,
  type RetrievalViewerDetails_viewer_retrieval_meeting as Meeting,
} from "../retrieval_query.graphql";
import RetrievalDetailsFailureModal from "../failure_modal";
import Styles from "./index.module.scss";

type StateParams = {
  retrievalPin: string;
};
type DocumentsSectionProps = {
  documentBundle: DocumentBundle;
};
type DetailsSectionProps = {
  documentBundle: DocumentBundle;
  meeting: Meeting;
};
type DownloadDocumentsButtonProps = {
  documentBundle: DocumentBundle;
  fullWidth?: boolean;
};

const MESSAGES = defineMessages({
  nextDocument: {
    id: "0c6c1a11-15f3-4efc-8cc1-a4e9f7a586be",
    defaultMessage: "Next document",
  },
  previousDocument: {
    id: "98df0807-f523-499c-af28-06d18d845e17",
    defaultMessage: "Previous document",
  },
});

function DownloadDocumentsButton({ documentBundle, fullWidth }: DownloadDocumentsButtonProps) {
  const [downloading, setDownloading] = useState(false);

  const downloadAll = useCallback(async () => {
    setDownloading(true);
    await asArchive(documentBundle);
    setDownloading(false);
  }, [documentBundle]);

  return (
    <Button
      automationId="download-all-button"
      onClick={downloadAll}
      isLoading={downloading}
      buttonSize="large"
      buttonColor="action"
      variant="primary"
      withIcon={{ name: "download", placement: "right" }}
      fullwidth={fullWidth}
    >
      <FormattedMessage
        id="93d66799-073c-4ae3-aa3f-a31a28676812"
        defaultMessage="Download documents"
      />
    </Button>
  );
}

function DetailsSection({ documentBundle, meeting }: DetailsSectionProps) {
  const isMobile = useMobileScreenClass();
  const { signers, transaction } = documentBundle;

  return (
    <div className={Styles.detailsContainer}>
      <Heading level="h1" textStyle="headingFour">
        {transaction.name}
      </Heading>
      {signers && (
        <div className={Styles.certificationDetails}>
          <Icon name="success" className={Styles.certifyIcon} />
          <Substyle textStyle="headingSix">{userFullName(signers[0])}</Substyle>

          <Paragraph>
            <FormattedMessage
              id="a8cb8207-e7c6-4591-83b0-415172c040ef"
              defaultMessage="IAL2 Certified on "
            />{" "}
            <LongFormattedDateTime value={meeting.timeFrame!.startedAt} asTimeZone="UTC" />
          </Paragraph>
        </div>
      )}
      {!isMobile && <DownloadDocumentsButton documentBundle={documentBundle} />}
    </div>
  );
}

function DocumentsSection({ documentBundle }: DocumentsSectionProps) {
  const intl = useIntl();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);

  const documentCount = documentBundle.documents.edges.length;

  const handlePrevious = () => {
    setCurrentDocumentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const handleNext = () => {
    setCurrentDocumentIndex((prevIndex) => Math.min(prevIndex + 1, documentCount - 1));
  };
  const currentDocument = documentBundle.documents.edges[currentDocumentIndex].node;

  const documents = (
    <div
      className={Styles.pdfContainer}
      data-automation-id="document-item"
      key={currentDocument.id}
    >
      {currentDocument.finalAsset && (
        <PDFViewer className={Styles.pdfViewer} url={currentDocument.finalAsset.url!} />
      )}
      <div className={Styles.documentName}>
        {documentCount > 1 && (
          <IconButton
            name="caret-left"
            label={intl.formatMessage(MESSAGES.previousDocument)}
            onClick={handlePrevious}
            disabled={currentDocumentIndex === 0}
          />
        )}
        <Paragraph>
          <SROnly>
            <FormattedMessage
              id="fcf5cd46-b1a6-46d0-a0eb-e773532ed394"
              defaultMessage="Current document:"
            />
          </SROnly>
          {currentDocument.name}
        </Paragraph>
        {documentCount > 1 && (
          <IconButton
            name="caret-right"
            label={intl.formatMessage(MESSAGES.nextDocument)}
            onClick={handleNext}
            disabled={currentDocumentIndex === documentCount - 1}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={Styles.documentsContainer} data-automation-id="documents-section">
      <Heading level="h2" textStyle="allCapsLabel" textColor="subtle">
        <FormattedMessage
          id="53779905-d57d-48d0-a43e-0ea28e919d8c"
          defaultMessage="{documentCount} {documentCount, plural, one {Document} other {Documents}}"
          values={{ documentCount }}
        />
      </Heading>

      {documents}
    </div>
  );
}

function CertifyRetrievalDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryArgs] = useSearchParams();
  const isMobile = useMobileScreenClass();
  const retrievalId = queryArgs.get("retrievalId") as string;
  const { retrievalPin } = (location.state || {}) as StateParams;

  const { data, loading, error } = useQuery(RetrievalViewerDetailsQuery, {
    variables: { retrievalId, retrievalPin },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const retrieval = data?.viewer.retrieval;
  if (error || !retrieval) {
    return <RetrievalDetailsFailureModal onRetry={() => navigate("/")} />;
  }
  const { meeting, documentBundle } = retrieval;

  return (
    <div className={Styles.certifyRetrievalContainer}>
      {meeting && <DetailsSection documentBundle={documentBundle} meeting={meeting} />}
      <DocumentsSection documentBundle={documentBundle} />
      {isMobile && (
        <div className={Styles.mobileButtonContainer}>
          <DownloadDocumentsButton fullWidth documentBundle={documentBundle} />
        </div>
      )}
    </div>
  );
}

const CertifyRetrievalDetailsWithMemo = memo(CertifyRetrievalDetails);

export { CertifyRetrievalDetailsWithMemo as CertifyRetrievalDetails };
