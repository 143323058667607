import type { ReactNode } from "react";

import { ProofFooter } from "common/proof_footer";

import RetrievalHeader from "../header";
import Styles from "./index.module.scss";

type Props = {
  children?: ReactNode;
  hasMobileFooterButton?: boolean;
};

export function VerifyFrame({ children, hasMobileFooterButton }: Props) {
  return (
    <div className={Styles.frame}>
      <RetrievalHeader />
      <main className={Styles.content}>{children}</main>
      <ProofFooter hasMobileFooterButton={hasMobileFooterButton} />
    </div>
  );
}
