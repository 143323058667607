import type { ReactNode } from "react";

import { CtaSection } from "./cta_section";
import { VerifyFrame } from "./verify_frame";

type Props = {
  children?: ReactNode;
  showCtaSection?: boolean;
  hasMobileFooterButton?: boolean;
};

function Retrieval({ children, showCtaSection = false, hasMobileFooterButton = false }: Props) {
  return (
    <VerifyFrame hasMobileFooterButton={hasMobileFooterButton}>
      {children}
      {showCtaSection && <CtaSection />}
    </VerifyFrame>
  );
}

export default Retrieval;
